import React from 'react';

export type BookSection = {
  sectionName: string;
  link: string;
};

export type BookSectionListProps = {
  bookSections: BookSection[];
};

const BookSectionList = (props: BookSectionListProps) => {
  const { bookSections } = props;

  return (
    <div className="">
      <h2>Section List</h2>

      <ul>
        {bookSections.map((bookSection) => (
          <li>{bookSection.sectionName}</li>
        ))}
      </ul>
    </div>
  );
};

export default BookSectionList;
