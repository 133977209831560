import React from 'react';
import BookSectionList, { BookSection } from '../BookSectionList/BookSectionList';

const BookLandingPage = () => {
  const bookSections: BookSection[] = []; // TODO: fetch from custom hook after ENG1-1099

  return (
    <div className="">
      <h2>Book Title Here</h2>

      <BookSectionList bookSections={bookSections} />
    </div>
  );
};

export default BookLandingPage;
