import React, { ComponentType, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';

import InterviewersSuperAdminTab from './SuperAdminTabs/Interviewers/InterviewersSuperAdminTab';
import RewardLinksSuperAdminTab from './SuperAdminTabs/RewardLinksSuperAdminTab';
import DCCoursesSuperAdminTab from './SuperAdminTabs/DCCourses/DCCoursesSuperAdminTab';
import CouponAndFreeSessionBanners from './CouponAndFreeSessionBanners/CouponAndFreeSessionBanners';
import InterviewAIPage from '../pages/InterviewAI/InterviewAI';
import SalaryNegotiationPage from '../pages/SalaryNegotiation/SalaryNegotiationPage';
import DedicatedCoachingPage from '../pages/DedicatedCoaching/DedicatedCoachingPage';
import CompanyIntroductionsPage from '../pages/CompanyIntroductionsPage/CompanyIntroductionsPage';
import ClaimInterviewList from '../pages/claims/ClaimInterviewList';
import ClaimInterview from '../pages/claims/ClaimInterview';
import SessionGrantsSuperAdminPage from '../pages/super-admin/session-grants/SessionGrants';
import ParticipantIntroductionPanel from './ParticipantIntroductionPanel/ParticipantIntroductionPanel';
import BookLandingPage from '../pages/Book/BookLandingPage/BookLandingPage';

const ComponentRegistry: Record<string, ComponentType> = {
  InterviewersSuperAdminTab,
  SessionGrantsAdminTab: SessionGrantsSuperAdminPage,
  RewardLinksSuperAdminTab,
  DCCoursesSuperAdminTab,
  CouponAndFreeSessionBanners,
  ClaimInterview,
  ClaimInterviewList,
  CompanyIntroductionsPage,
  DedicatedCoachingPage,
  SalaryNegotiationPage,
  InterviewAIPage,
  ParticipantIntroductionPanel,
  BookLandingPage,
};

export default function ReactInNgPortalManager() {
  const [portals, setPortals] = useState<Record<string, JSX.Element>>({});

  useEffect(() => {
    window.reactInNgPortalManager = {
      addPortal: function <P>(key: string, componentId: string, container: HTMLElement, props: P): JSX.Element {
        const component: ComponentType | undefined = ComponentRegistry[componentId];
        if (!component) {
          window.Rollbar.error(`React component {componentId} not in the registry!`);
          return;
        }
        const portal: JSX.Element = React.createElement(component, props);
        setPortals((prevPortals) => ({
          ...prevPortals,
          [key]: ReactDOM.createPortal(portal, container),
        }));
      },
      removePortal: function (key: string) {
        setPortals((prevPortals) => {
          const newPortals = { ...prevPortals };
          delete newPortals[key];
          return newPortals;
        });
      },
    };
  }, []);

  return <>{Object.values(portals)}</>;
}
