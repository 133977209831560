import dayjs from 'dayjs';
import {
  CalendarProps,
  MonthType,
  AvailabilityMap,
  Availability,
  APIAvailability,
  APIAvailabilityData,
  TimeSlotsWithAvailabilityType,
  OrganizationType,
  TimeSlotWithAvailability,
  ExistingRoundsMappedType,
  ExistingRoundsType,
} from './Types';

export const dateFormat = 'YYYY-MM-DD';
export const monthFormat = 'MMMM';

export const daysAheadPadding = 14;
// Business logic here is to add 14 days (two weeks) in ADDITION to the rest of the current week.
export const getDaysAhead = (date: dayjs.Dayjs): number => 6 - date.day() + 14;
export const earlyTimeCutOff = 8;

export const getBaseCalendarData = (today: dayjs.Dayjs, daysAhead: number, monthsAhead: number): MonthType[] => {
  const buildMonthArray = (props: CalendarProps): MonthType => {
    const { date, daysAllowed } = props;
    //const today = dayjs(); // Used for universal "isPast/isFuture" so we don't pass in
    const now = dayjs(date);
    const monthText = now.format(monthFormat);
    const firstOfMonth = now.date(1);
    const daysInMonth = now.daysInMonth();
    const firstDayOfMonth = firstOfMonth.day(); // Prepadding, find "day" of week of the first, so we can backfill before it
    const days = [];
    let daysPossible = 0;
    let daysUsed = 0;
    // 1. Days from PREVIOUS month
    // Working backwards from start of "current" month
    for (let i = -1 * (firstDayOfMonth - 1); i <= 0; i++) {
      const temp = firstOfMonth.date(i);
      const formattedDate = temp.format(dateFormat);
      days.push({
        date: formattedDate,
        idx: i,
        display: temp.format('D'),
        isFuture: false,
        isAvailable: false,
        isCurrentMonth: false,
        isInPast: true,
        isToday: false,
        slots: 0,
      });
    }
    // 2. Days of CURRENT Month (passed in)
    for (let i = 1; i <= daysInMonth; i++) {
      const temp = now.date(i);
      const formattedDate = temp.format(dateFormat);
      const isFuture = temp > today;
      const item = {
        date: formattedDate,
        idx: i,
        display: temp.format('D'),
        isFuture,
        isAvailable: temp >= today && daysPossible < daysAllowed,
        isCurrentMonth: true,
        isInPast: temp < today,
        isToday: formattedDate === today.format(dateFormat),
        slots: 0,
      };
      days.push(item);
      if (item.isFuture) daysPossible++;
      if (item.isAvailable) daysUsed++;
    }
    // 3. Days from NEXT month
    // Make sure the final padding always is set to fill out 42 slots (6 weeks) so all calendar views are the same height
    const finalPadding = 42 - days.length;
    for (let i = 1; i <= finalPadding; i++) {
      const temp = now.set('month', now.get('month') + 1).date(i);
      const formattedDate = temp.format(dateFormat);
      days.push({
        date: formattedDate,
        idx: i,
        display: temp.format('D'),
        isFuture: true,
        isAvailable: false,
        isCurrentMonth: false,
        isInPast: false,
        isToday: false,
        slots: 0,
      });
    }
    // RESULT
    // days: day items to display (including padding)
    // daysUsed: how may "allowed" days were used this month
    // daysPossible: days we could have "used" for this month, if not for allow limit etc
    // monthText: display test for month
    return { days, daysUsed, daysPossible, monthText };
  };

  const months = [];
  let allowedDaysLeft = daysAhead;

  for (let i = 0; i < monthsAhead; i++) {
    const one = buildMonthArray({
      date: today.add(i, 'month'),
      daysAllowed: allowedDaysLeft,
    });
    months.push(one);
    allowedDaysLeft = allowedDaysLeft - one.daysUsed;
  }

  return months;
};

export const SCHEDULER_TIME_SLOT_TYPE = {
  BUSY: 0,
  AVAILABLE: 1,
  PLACEHOLDER: 2,
};

export const filterAvailabilties = (availabilities: AvailabilityMap, focus: string): AvailabilityMap => {
  const result: AvailabilityMap = {};
  for (const [key, value] of Object.entries<APIAvailability>(availabilities)) {
    const hasFocus = Array.isArray(value.focuses) && value.focuses.includes(focus);
    if (hasFocus) result[key] = value;
  }
  return result;
};

export const isFocusEnabledForOrg = (org: OrganizationType, focus: string): boolean =>
  !org || // peer
  (!org._isPremiumPractice && !org._isDedicatedCoachingOrg) || // standalone non-premium practice / DC orgs always use 'standard' focus
  org.accountSettings.standalone.premiumPractice.roundFocuses.includes(focus); // premium org focuses may change

export const filterAndMapSlotAvailabilities = (
  availabilities: AvailabilityMap,
  slotAvailabilities: string[],
  timeSlot: dayjs.Dayjs,
  focus: string
): Availability[] => {
  const result: Availability[] = [];
  for (const key of slotAvailabilities) {
    if (availabilities[key] !== undefined) {
      const entry = {
        ...availabilities[key],
        startDate: timeSlot,
        endDate: timeSlot.add(1, 'hour'),
        type: SCHEDULER_TIME_SLOT_TYPE.AVAILABLE,
        focus,
      };
      result.push(entry);
    }
  }
  return result;
};

export const transformAvailabilityData = (
  data: APIAvailabilityData,
  focus: string,
  org: OrganizationType
): TimeSlotsWithAvailabilityType => {
  if (!isFocusEnabledForOrg(org, focus)) return {};
  const { timeSlots, availabilities: rawAvailabilities } = data;
  const availabilities = filterAvailabilties(rawAvailabilities, focus);

  const result: TimeSlotsWithAvailabilityType = {};
  for (const [key, value] of Object.entries(timeSlots)) {
    const timeSlot = dayjs(new Date(key));
    const slotAvailabilities = filterAndMapSlotAvailabilities(availabilities, value, timeSlot, focus);

    const selected = slotAvailabilities?.length ? slotAvailabilities[0] : null;

    const tsId = timeSlot.format(dateFormat);
    if (result[tsId] === undefined) {
      result[tsId] = [];
    }

    const early = timeSlot.hour() < earlyTimeCutOff;
    const timeString = timeSlot.format('HH:mm:ssZ[Z]');
    const display = timeSlot.format('h:mma');
    const dateTime = timeSlot.format();
    const row = { id: timeString, dateTime, display, early, selected };
    result[tsId].push(row);
  }
  for (const [key, value] of Object.entries(result)) {
    result[key] = value.sort((a, b) => dayjs(a.dateTime).valueOf() - dayjs(b.dateTime).valueOf());
  }
  return result;
};

export const mergeBaseWithAvailabilities = (
  base: MonthType[],
  transformed: TimeSlotsWithAvailabilityType
): MonthType[] => {
  const result: MonthType[] = [...base];
  for (let i = 0; i < result.length; i++) {
    const month = result[i];
    for (let j = 0; j < month.days.length; j++) {
      const day = month.days[j];
      const transformedDay = transformed[day.date];
      if (transformedDay !== undefined && transformedDay?.length) {
        day.slots = transformedDay.length;
      }
    }
  }
  return result;
};

export const shouldHideEarlyTimes = (timeSlots: TimeSlotWithAvailability[]) => {
  let earlyCount = 0;
  let lateCount = 0;
  for (const timeSlot of timeSlots) {
    if (timeSlot.early) {
      earlyCount++;
    } else {
      lateCount++;
    }
  }
  return earlyCount > 0 && lateCount > 0;
};

export const mapExistingRounds = (existing: ExistingRoundsType[]) => {
  const result: ExistingRoundsMappedType = {};
  for (const entry of existing) {
    const key = dayjs(entry.start).format();
    result[key] = true;
  }
  return result;
};
